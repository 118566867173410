@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800&display=swap');

.container{
    max-width: 100%;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    margin: 0 auto;
}

.flexbox{
    display: flex;
    position: relative;
    align-items: center;
    /* justify-content: inherit; */
}

.ml5{
    margin-left: 5px;
}

.pointer{
    cursor: pointer;
}


/* skleton loader */

@keyframes skeleton-glare {
    to {
        transform: translateX(100%)
	}
}

.skletonLoader{
    position: relative;
    overflow: hidden;
    width: 100%;
    padding: 10px;
}

.skletonLoader::after{
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: var(--glare-bg);
    transform: translateX(-100%);
    animation: skeleton-glare 1.75s infinite ease-out;
    z-index: 1;
}

.w100{
    width: 100%;
}

::placeholder {
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    color: var(--grey);
    font-family: 'inter';
}

.SmenuMid{
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: var(--black3);
}

.multiSelectContainer li{
    font-size: 13px;
    font-weight: 400;
    color: var(--black);
}

@media (max-width: 1024px) and (max-width: 1280px){
    ::placeholder{
        font-size: 12px;
    }
}