.LogoutPopup{
    width: 400px;
}

.LogoutPopup .popupHeader{
    border-bottom: none;
    justify-content: flex-end;
    padding: 15px 20px 0;
}

.LogoutPopup .close{
    border: none;
    padding: 0;
}

.LogoutPopup .popupBody{
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
    min-height: max-content;
    padding: 0 20px;
    padding-bottom: 40px;
}

.popBodyWrap{
    position: relative;
    width: 100%;
    line-height: 0;
    justify-content: center;
}

.logOutText{
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    max-width: 200px;
    display: block;
    text-align: center;
}

.LogoutPopup .buttonWrapper{
    justify-content: center;
    width: 100%;
}

.LogoutPopup .buttonWrapper button{
    padding: 10px 20px;
    font-size: 14px;
}