.sidemenuWrap {
    flex-wrap: wrap;
    width: 100%;
    flex-direction: column;
    height: calc(100vh - 50px);
    justify-content: space-between;
}

.sideMenuListUl {
    width: 100%;
}

.sideMenu {
    max-width: 282px;
    width: 100%;
    height: 100vh;
    border-right: 1px solid var(--grey2);
}

.SidemenuList:not(:first-child) {
    margin-top: 5px;
}

.SidemenuList {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 8px 14px;
    gap: 5px;
    position: relative;
    transition: 0.4s ease-in-out;
    cursor: pointer;
    font-size: 14px;
    color: var(--black3);
    border-left: 2px solid transparent;
}

.SidemenuList svg path {
    color: var(--grey2);
}

.SidemenuList.active svg path {
    stroke-width: 0.4px;
}

/* .SidemenuList span{
    padding: 8px 14px;
    padding-left: 0;
} */

.iconToggler {
    width: 100%;
    justify-content: space-between;
}

.SidemenuList.active {
    background: rgba(0, 82, 204, 0.1);
    color: var(--blue);
    transition: 0.4s ease-in-out;
    border-color: var(--blue);
}

.SidemenuList:hover{
    background: rgba(0, 82, 204, 0.1);
    color: var(--blue);
}

.SidemenuList:hover svg path{
    stroke: var(--blue);
    stroke-width: 0.4px;
}


.SidemenuList.active {
    border-color: var(--blue) !important;
}

.SidemenuList:hover {
    border-color: transparent;
}

.SidemenuList .iconToggler svg {
    transform: rotate(0deg);
}

.SidemenuList.active .iconToggler svg {
    transform: rotate(90deg);
}

.SidemenuList.active svg path {
    stroke: var(--blue);
}

.sideLogo {
    padding: 16px 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
}

.expenseMenu .SidemenuList {
    padding: 14px;
}

.expenseMenu .SidemenuList:not(:first-child) {
    margin-top: 5px;
}

.sideMenuListUl .smallSubmenu {
    height: 0;
    max-height: 0;
    overflow: hidden;
    transition: 0.4s ease-in;
    background: var(--grey5);
    border-top: 1px solid var(--white);
}

.sideMenuListUl .smallSubmenu.current {
    height: 100%;
    max-height: 145px;
    transition: 0.4s ease-in;
}

.smallSubmenu .SidemenuList.active {
    background: #e7edf9;
}

.expenseMenu .bottomMenu {
    margin-top: 0;
    margin-bottom: 12px;
}

.expenseMenu .bottomMenu .SidemenuList:not(:first-child) {
    margin-top: 10px;
}

.sideMenuListWrap {
    margin-top: 20px;
    overflow: auto;
    max-height: calc(100vh - 70px);
}

.logout {
    color: var(--red);
}

.logout:hover {
    color: var(--red);
    background: rgb(229 125 127 / 16%);
    border-color: transparent;
}

.logout svg path {
    fill: var(--red);
}

.logout:hover svg path {
    fill: var(--red);
    stroke: none;
}

.SmenuLeft {
    min-width: 250px;
}

.SmenuRight {
    min-width: 250px;
    justify-content: flex-end !important;
}

.SmenuRight  .iconButton{
    font-size: 14px;
}